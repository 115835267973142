import en from './locales/en.json';
import el from './locales/gr.json';

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'el',
    fallbackLocale: 'en',
    locales: ['en', 'el'],
    globalInjection: true,
    messages: {
        en,
        el
    }
}))